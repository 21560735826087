<template>
  <div>
    <v-card>
      <v-card-title>Lista de alumnos</v-card-title>
      <div v-if="groups_loaded">
        <v-container>
          <v-row justify="space-between">
            <v-col align-self="center" cols="12" md="5">
              <v-select
                return-object
                :items="grupos_arr"
                item-text="title"
                item-value="id"
                label="Seleciona el grupo"
                v-model="id_grupo_selected"
                @change="change_id_state()"
              ></v-select>
            </v-col>
            <!--<v-col cols="12" md="2">
              <v-btn
                color="primary"
                block
                @click="get_alumnos()"
                :disabled="!id_grupo_selected"
              >
                Ver alumnos
              </v-btn>
            </v-col>-->
            <v-col cols="12" md="2">
              <export-csv
                :disabled="!id_grupo_selected"
                :idgrupo="id_grupo_selected"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-card>
            <v-card-title>Lista de estudiantes</v-card-title>
            <v-simple-table dense v-if="students_loaded">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">#</th>
                    <th class="text-left">
                      Apellidos y Nombres
                    </th>
                    <th class="text-left">
                      Grupo
                    </th>
                    <th class="text-left">
                      DNI
                    </th>
                    <th class="text-left">
                      Correo
                    </th>
                    <th class="text-center">
                      Periodos académicos restringidos de visibilidad al alumno
                    </th>
                    <th class="text-center">Ver libreta de notas</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in students" :key="item.idalumno">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.nombre }}</td>
                    <td>{{ item.grupo }}</td>
                    <td>{{ item.dni }}</td>
                    <td>{{ item.correo }}</td>
                    <td>
                      <periodos-restringidos-check
                        :idalumno="item.idalumno"
                        :periodos="periodos_academicos"
                        v-if="isloaded_periodos"
                      />
                    </td>
                    <td class="text-center">
                      <v-btn
                        icon
                        color="primary"
                        @click="go_detail(item.idalumno)"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-progress-linear
              v-else
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="4"
            ></v-progress-linear>
          </v-card>
        </v-container>
      </div>
      <div v-else>
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      </div>
    </v-card>
  </div>
</template>
<script>
import ExportCsv from "@/components/ExportCsv.vue";
import PeriodosRestringidosCheck from "@/components/PeriodosRestringidosCheck.vue";
import axios from "axios";
import api from "@/api.config.js";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    ExportCsv,
    PeriodosRestringidosCheck,
  },
  data: () => ({
    groups_loaded: false,
    students_loaded: false,
    grupos_arr: [],
    id_grupo_selected: null,
    students: [],
    periodos_academicos: [],
    isloaded_periodos: false,
  }),
  computed: {
    ...mapState(["grupo_selected"]),
  },
  methods: {
    ...mapMutations(["set_grupo_selected"]),
    change_id_state() {
      this.set_grupo_selected(this.id_grupo_selected.id);
      this.get_alumnos();
    },
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },
    get_periodo_academico() {
      let anio = 2021;
      axios
        .get(
          api.API_PATH + "/api/get-periodo-academico/" + anio + "/",
          this.get_config()
        )
        .then((r) => {
          this.periodos_academicos = r.data;
          //console.log("periodos",r.data)
          this.periodos_academicos.sort(function(a, b) {
            if (a.id > b.id) return 1;
            if (a.id < b.id) return -1;
            return 0;
          });
          this.isloaded_periodos = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_grupos() {
      axios
        .get(api.API_PATH + "/api/grupos-detalle/", this.get_config())
        .then((r) => {
          this.grupos_arr = [];
          let datos = r.data;
          datos.forEach((e) => {
            this.grupos_arr.push({
              id: e.id,
              title:
                e.nivel.descripcion +
                " " +
                e.grado.descripcion +
                " " +
                e.seccion.descripcion,
            });
          });

          let res = this.grupos_arr.find((e) => e.id == this.grupo_selected);
          if (res) {
            this.id_grupo_selected = res;
          }

          this.get_alumnos();
          this.groups_loaded = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    get_alumnos() {
      //console.log(this.id_grupo_selected)
      this.students_loaded = false;
      axios
        .get(
          api.API_PATH +
            "/api/listar-estudiantes-por-grupo/" +
            this.grupo_selected +
            "/",
          this.get_config()
        )
        .then((r) => {
          // console.log(r.data);
          let datos = r.data;
          this.students = [];
          datos.forEach((e) => {
            this.students.push({
              nombre:
                e.alumno.paterno + " " + e.alumno.materno + " " + e.alumno.name,
              grupo:
                e.grupo.grado.descripcion +
                " " +
                e.grupo.seccion.descripcion +
                " - " +
                e.grupo.nivel.descripcion,
              dni: e.alumno.user.dni,
              correo: e.alumno.user.email,
              idalumno: e.alumno.id,
            });
          });
          this.students_loaded = true;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    go_detail(id) {
      this.$router.push({
        name: "NotasPorGrupoDetalle",
        params: { idalumno: id },
      });
    },
  },
  mounted() {
    this.get_grupos();
    this.get_periodo_academico();
  },
};
</script>
