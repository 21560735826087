<template>
  <div>
    <div v-if="loaded" style="display:flex; justify-content:space-around;">
      <div v-for="item in periodos_restringidos" :key="item.id" cols="3">
        <v-checkbox
          dense
          color="red"
          :label="item.denominacion[0] + item.denominacion.split(' ')[1]"
          :input-value="item.status"
          value
          :disabled="isloading_datasend"
          @change="process_change(item.idbl, item.id)"
        ></v-checkbox>
      </div>
    </div>
    <v-row justify="center" v-else>
      <v-col cols="2">
        <v-progress-circular
          indeterminate
          :size="20"
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import api from "../api.config";
export default {
  name: "PeriodoRestringidosCheck.vue",
  props: ["idalumno", "periodos"],
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 2000,
    loaded: false,
    periodos_restringidos: [],
    isloading_datasend: false,
  }),
  methods: {
    get_config() {
      let tok = JSON.parse(localStorage.getItem("token-user"));
      let token = tok["token-user"];
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + token,
        },
      };
      return config;
    },

    get_black_list() {
      ///api/obtener-lista-negra-por-alumno/2/
      this.isloading_datasend = true;
      axios
        .get(
          api.API_PATH +
            "/api/obtener-lista-negra-por-alumno/" +
            this.idalumno +
            "/",
          this.get_config()
        )
        .then((r) => {
          this.format_data(r.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    format_data(restringidos) {
      let aux = [];
      this.periodos.forEach((e) => {
        let res = restringidos.find((ele) => ele.periodo_academico == e.id);
        if (res) {
          aux.push({
            id: e.id,
            idbl: res.id,
            denominacion: e.denominacion,
            status: true,
            alumnoid: this.idalumno,
          });
        } else {
          aux.push({
            id: e.id,
            idbl: null,
            denominacion: e.denominacion,
            status: false,
            alumnoid: this.idalumno,
          });
        }
      });
      this.periodos_restringidos = aux;
      this.loaded = true;
      this.isloading_datasend = false;
    },
    process_change(val, periodo) {
      this.isloading_datasend = true;
      if (val) {
        this.delete_black_list(val);
      } else {
        this.add_black_list(periodo);
      }
    },
    delete_black_list(id) {
      axios
        .delete(
          api.API_PATH + "/api/delete-lista-negra/" + id + "/",
          this.get_config()
        )
        .then(() => {
          this.snackbar = true;
          this.text = "Eliminado a la lista negra :D";
          this.get_black_list();
        })
        .catch((e) => {
          this.snackbar = true;
          this.text = e;
          this.get_black_list();
        });
    },
    add_black_list(id) {
      const body = {
        estado: true,
        alumno: this.idalumno,
        periodo_academico: id,
      };
      axios
        .post(
          api.API_PATH + "/api/create-lista-negra/",
          body,
          this.get_config()
        )
        .then(() => {
          this.snackbar = true;
          this.text = "Agregado a la lista negra :(";
          this.get_black_list();
        })
        .catch((e) => {
          this.snackbar = true;
          this.text = e;
          this.get_black_list();
        });
    },
  },

  mounted() {
    this.get_black_list();
  },
};
</script>
